import React, {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

import 'reactflow/dist/style.css'
import { Box, Stack } from '@mui/material'
import ReactFlow, {
  addEdge,
  applyNodeChanges,
  Background,
  BackgroundVariant,
  ConnectionLineType,
  ConnectionMode,
  Edge,
  MarkerType,
  Node,
  NodeChange,
  useEdgesState,
  useReactFlow,
} from 'reactflow'
import {
  CreateReactMapMutation,
  CreateReactMapMutationVariables,
  DeleteReactMapMutation,
  DeleteReactMapMutationVariables,
  LearnerStatus,
  TableName,
  UpdateReactMapMutation,
  UpdateReactMapMutationVariables,
} from 'types/graphql'
import { useBoolean } from 'usehooks-ts'
import { v4 as uuid } from 'uuid'

import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import {
  reactMapConfig,
  reactMapConfigSuperAdmin,
} from 'src/components/Library/RecordSettingsModal/config'
import RecordSettingsModal from 'src/components/Library/RecordSettingsModal/RecordSettingsModal'
import ReactMapControlPanel from 'src/components/ReactMap/ReactMapControlPanel'
import ReactMapControlPanelUpperToolbar from 'src/components/ReactMap/ReactMapControlPanelUpperToolbar'
import ReactMapCustomNode from 'src/components/ReactMap/ReactMapCustomNode'
import {
  CategoryList,
  CellMapInTask,
  ReactMapItem,
} from 'src/components/ReactMap/ReactMapEditorCell/ReactMapEditorCell'
import ReactMapGroupNode from 'src/components/ReactMap/ReactMapGroupNode'
import ReactMapHelperLines from 'src/components/ReactMap/ReactMapHelperLines'
import './index.css'
import {
  defaultEdgeOptions,
  getHelperLines,
  getNodePositionInsideParent,
  lineShapes,
  sortNodes,
} from 'src/components/ReactMap/utils'
import { useConfirm } from 'src/lib/hooks/Confirmation'
import useAnalytics from 'src/lib/hooks/useAnalytics'
import UseDebounce from 'src/lib/hooks/UseDebounce'
import {
  DELETE_REACT_MAP,
  UPDATE_REACT_MAP,
} from 'src/lib/queries/learner/ReactMaps/ReactMap'
import { CREATE_REACT_MAP } from 'src/lib/queries/learner/ReactMaps/reactMapCategories'
import useReactFlowStore from 'src/lib/stores/reactMapStores'
import { useAuth } from 'src/Providers'

import { LinkedResourceType } from '../HubDash/HubDashLayoutListCell'
import LinkedContentBadge, {
  type LinkedResourceRow,
} from '../Library/LinkedContentBadge/LinkedContentBadge'

import ReactMapDeleteDescription from './ReactMapDeleteDescription'

export interface ReactMapEditorProps {
  mapItem?: ReactMapItem
  fullScreenMap: boolean
  setFullScreenMap: Dispatch<SetStateAction<boolean>>
  mapInTasks: CellMapInTask
  categoryList: CategoryList
}

const ReactMapEditor: FC<ReactMapEditorProps> = ({
  mapItem,
  fullScreenMap,
  setFullScreenMap,
  categoryList,
  mapInTasks,
}) => {
  const { hasRole } = useAuth()
  const proOptions = { account: 'paid-pro', hideAttribution: true }

  const { trackEvent } = useAnalytics()

  const nodes = useReactFlowStore((state) => state.nodes)
  const setNodes = useReactFlowStore((state) => state.setNodes)
  const isEditing = useReactFlowStore((state) => state.isEditing)
  const setIsEditing = useReactFlowStore((state) => state.setIsEditing)
  const handleSetNodeText = useReactFlowStore(
    (state) => state.handleSetNodeText,
  )
  const setSearchText = useReactFlowStore((state) => state.setSearchText) // Function to set the state of searchText

  const [edges, setEdges, onEdgesChange] = useEdgesState<Edge[]>(
    JSON.parse(mapItem.connectors as string) as Record<string, any> as Edge[],
  )

  const [helperLineHorizontal, setHelperLineHorizontal] =
    useState<number>(undefined)
  const [helperLineVertical, setHelperLineVertical] =
    useState<number>(undefined)
  const [reactFlowInstance, setReactFlowInstance] = useState(null)
  const [allowNodeEditing, setAllowNodeEditing] = useState(false)
  const [allowStrokeEditing, setAllowStrokeEditing] = useState(false)
  const [nodeFillColor, setNodeFillColor] = useState('')
  const [nodeStrokeColor, setNodeStrokeColor] = useState('')
  const [edgeStrokeType, setEdgeStrokeType] = useState('')
  const [edgeEndOne, setEdgeEndOne] = useState('')
  const [edgeEndTwo, setEdgeEndTwo] = useState('')
  const [edgeStrokeColor, setEdgeStrokeColor] = useState('')
  const [edgeStrokeThickness, setEdgeStrokeThickness] = useState<
    string | number
  >('')
  const [edgeLabelText, setEdgeLabelText] = useState('')
  const [nodeLabelText, setNodeLabelText] = useState('')
  const [nodeFontSize, setNodeFontSize] = useState(12)
  const [nodeLabelColour, setNodeLabelColour] = useState('')
  const [selectedNodeShape, setSelectedNodeShape] = useState('')
  const [nodeUrl, setNodeUrl] = useState('')
  const [legend, setLegend] = useState(JSON.parse(mapItem.legend as string))
  const [saveMapLoading, setSaveMapLoading] = useState(false)
  const [deleteReactMapLoading, setDeleteReactMapLoading] = useState(false)
  const [duplicateMapLoading, setDuplicateMapLoading] = useState(false)
  const [publishingMapLoading, setPublishingMapLoading] = useState(false)
  const [mapName, setMapName] = useState(mapItem.name)
  const reactFlowWrapper = useRef(null)
  const legendRef = useRef(null)
  const settingsModal = useBoolean(false)
  const { getIntersectingNodes } = useReactFlow()
  const isSuperAdmin = hasRole('SUPERADMIN')
  const confirm = useConfirm()
  const debouncedNodes = UseDebounce(nodes, 1000)
  const debouncedEdges = UseDebounce(edges, 1000)

  const [isUndoing, setIsUndoing] = useState(false)
  const [hasSwitchedMap, setHasSwitchedMap] = useState(false)

  const [linkedWorkflowsRows, setLinkedWorkflowsRows] = useState<
    LinkedResourceRow[]
  >([])

  const [updateMapMutationWithRefetch] = useMutation<
    UpdateReactMapMutation,
    UpdateReactMapMutationVariables
  >(UPDATE_REACT_MAP, {
    onCompleted: () => {
      toast.success('Map Saved')
    },
    refetchQueries: [
      'FindReactMapsSidePanelQuery',
      'FindReactMapEditorQuery',
      'FindReactMapQuery',
    ],
    awaitRefetchQueries: true,
    onError: (error) => toast.error(error.message),
  })

  const [updateMapMutationWithoutRefetch] = useMutation<
    UpdateReactMapMutation,
    UpdateReactMapMutationVariables
  >(UPDATE_REACT_MAP, {
    onCompleted: async () => {},
    onError: (error) => toast.error(error.message),
  })

  const updateMap = async (mapId, useRefetch) => {
    if (!isEditing) {
      return
    }
    const input = {
      name: mapName,
      nodes: JSON.stringify(nodes),
      connectors: JSON.stringify(edges),
      legend: JSON.stringify(legend),
    }

    if (useRefetch) {
      await updateMapMutationWithRefetch({
        variables: { id: mapId, input: input },
      })
    } else {
      await updateMapMutationWithoutRefetch({
        variables: { id: mapId, input: input },
      })
    }
  }

  const publishMap = async () => {
    setPublishingMapLoading(true)

    const mapId = mapItem.id
    const input = {
      status:
        mapItem.status === 'DRAFT'
          ? ('PUBLISHED' as LearnerStatus)
          : ('DRAFT' as LearnerStatus),
    }
    await updateMapMutationWithRefetch({
      variables: { id: mapId, input: input },
    })
    setPublishingMapLoading(false)
    trackEvent('Process Maps', 'toggle publish map')
  }

  const saveMap = async (mapId, useRefetch) => {
    setSaveMapLoading(true)
    if (isEditing) {
      await updateMap(mapId, useRefetch)
    }
    setSaveMapLoading(false)
  }

  const [deleteMapMutation] = useMutation<
    DeleteReactMapMutation,
    DeleteReactMapMutationVariables
  >(DELETE_REACT_MAP, {
    onCompleted: () => {
      toast.success('Map Deleted')
    },
    refetchQueries: [
      'FindReactMapsSidePanelQuery',
      'FindReactMapEditorQuery',
      'FindReactMapQuery',
    ],
    awaitRefetchQueries: true,

    onError: (error) => toast.error(error.message),
  })

  const deleteReactMap = async () => {
    setDeleteReactMapLoading(true)
    const mapId = mapItem.id

    await deleteMapMutation({
      variables: { id: mapId },
    })
    setIsEditing(!isEditing)
    setAllowNodeEditing(false)
    setAllowStrokeEditing(false)
    setDeleteReactMapLoading(false)
    trackEvent('Process Maps', 'delete map')
  }

  const cancelSavingMap = () => {
    setSaveMapLoading(true)
    setIsEditing(!isEditing)
    setAllowNodeEditing(false)
    setAllowStrokeEditing(false)
    setSaveMapLoading(false)
  }

  const [createMapMutation] = useMutation<
    CreateReactMapMutation,
    CreateReactMapMutationVariables
  >(CREATE_REACT_MAP, {
    onCompleted: () => {
      toast.success('Map created')
    },
    refetchQueries: [
      'FindReactMapsSidePanelQuery',
      'FindReactMapEditorQuery',
      'FindReactMapQuery',
    ],
    awaitRefetchQueries: true,
    onError: (error) => {
      toast.error(error.message)
    },
  })

  const duplicateNode = async () => {
    const selectedNodeId = nodes.find((node) => node.selected === true)?.id
    if (selectedNodeId) {
      const selectedNode = nodes.find((node) => node.id === selectedNodeId)

      const newPosition = {
        x: selectedNode.position.x + 20,
        y: selectedNode.position.y + 20,
      }

      const extraNode = {
        id: uuid(),
        data: selectedNode.data,
        position: newPosition,
        type: selectedNode.type,
      }

      setNodes([...nodes, extraNode])
    }
  }

  const duplicateMap = async () => {
    setDuplicateMapLoading(true)
    const result = await createMapMutation({
      variables: {
        input: {
          name: mapName + ' (Copy)',
          description: mapItem.description,
          diagramData: {},
          isVisible: mapItem.isVisible,
          legend: mapItem.legend,
          reactMapCategoryId: mapItem.reactMapCategoryId,
          connectors: mapItem.connectors,
          nodes: mapItem.nodes,
        },
      },
    })
    setDuplicateMapLoading(false)
    trackEvent('Process Maps', 'duplicate map')
    return result
  }

  const deselectNodes = () => {
    const updatedNodes = nodes.map((node) => {
      return { ...node, selected: false }
    })
    const updatedEdges = edges.map((edge) => {
      return { ...edge, selected: false }
    })
    setNodes(updatedNodes)
    setEdges(updatedEdges)
  }

  const handleSetLegend = () => {
    const uniqueShapeColors = Array.from(
      new Set(
        nodes
          .filter(
            (node) =>
              node.data &&
              node.data.shapeColor !== null &&
              !lineShapes.includes(node.data.shape) &&
              node.data.shape !== 'text',
          )
          .map((node) => node.data.shapeColor),
      ),
    )
    return uniqueShapeColors.map((color) => {
      const existingItem = legend
        ? legend.find((item) => item.color === color)
        : null
      if (!existingItem) {
        return {
          label: 'New Legend Item',
          color: color,
        }
      }
      return existingItem
    })
  }

  const onDropNode = useCallback(
    (event) => {
      event.preventDefault()
      const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect()
      const type = event.dataTransfer.getData('application/reactflow')

      if (typeof type === 'undefined' || !type) {
        return
      }

      const position = reactFlowInstance.project({
        x: event.clientX - reactFlowBounds.left - 100,
        y: event.clientY - reactFlowBounds.top - 100,
      })

      const newNode = {
        id: uuid(),
        type:
          type.indexOf('swimlane') > 0
            ? 'ReactMapGroupNode'
            : 'ReactMapCustomNode',
        position,
        data: {
          shape: type,
          label: type.indexOf('swimlane') > 0 ? 'New Swimlane' : 'New Node',
          shapeColor: '#ffffff',
          strokeColor: '#000000',
          labelColor: '#000000',
          strokeWidth: 2,
          fontSize: 12,
          width: type.indexOf('swimlane') > 0 ? 200 : 100,
          height: type.indexOf('swimlane') > 0 ? 200 : 100,
        },
      }

      useReactFlowStore.setState((state) => {
        const currentNodes = state.nodes

        const updatedNodes = [...currentNodes, newNode].sort(sortNodes)

        return { nodes: updatedNodes }
      })
    },
    [reactFlowInstance, reactFlowWrapper, uuid, sortNodes],
  )

  const onNodeDragStop = useCallback(
    (_event: React.MouseEvent, node: Node) => {
      if (node.type !== 'ReactMapCustomNode' && !node.parentNode) {
        return
      }

      const intersections = getIntersectingNodes(node).filter(
        (n) => n.type === 'ReactMapGroupNode',
      )
      const groupNode = intersections[0]

      if (intersections.length && node.parentNode !== groupNode?.id) {
        useReactFlowStore.setState((state) => {
          const nextNodes = state.nodes
            .map((n) => {
              if (n.id === groupNode.id) {
                return {
                  ...n,
                  className: '',
                }
              } else if (n.id === node.id) {
                const position = getNodePositionInsideParent(n, groupNode) ?? {
                  x: 0,
                  y: 0,
                }

                return {
                  ...n,
                  position,
                  parentNode: groupNode.id,
                  dragging: false,
                  extent: 'parent',
                }
              }
              return n
            })
            .sort(sortNodes)

          return { nodes: nextNodes }
        })
      }
    },
    [getIntersectingNodes, getNodePositionInsideParent, sortNodes],
  )

  const undo = () => {
    setIsUndoing(true)
    const { history, setHistory } = useReactFlowStore.getState()

    const filteredHistory = {
      undo: history.undo.filter((item) => item.mapId === mapItem.id),
      redo: history.redo.filter((item) => item.mapId === mapItem.id),
    }

    if (filteredHistory.undo.length > 0) {
      const { nodes, edges } = filteredHistory.undo[history.undo.length - 1]
      const newUndo = filteredHistory.undo.slice(0, -1)
      const newRedo = [
        { nodes: useReactFlowStore.getState().nodes, edges, mapId: mapItem.id },
        ...filteredHistory.redo,
      ]

      // Update nodes and edges to their previous state
      useReactFlowStore.setState({ nodes })
      setEdges(edges) // Assuming setEdges is your method to update edges state

      // Update history
      setHistory({
        ...filteredHistory,
        undo: newUndo,
        redo: newRedo,
      })
    }
  }

  const redo = () => {
    setIsUndoing(true)
    const { history, setHistory } = useReactFlowStore.getState()

    if (history.redo.length > 0) {
      const { nodes, edges } = history.redo[0]
      const newRedo = history.redo.slice(1)
      const newUndo = [
        ...history.undo,
        { nodes: useReactFlowStore.getState().nodes, edges },
      ]

      // Reapply nodes and edges from redo stack
      useReactFlowStore.setState({ nodes })
      setEdges(edges) // Assuming setEdges is your method to update edges state

      // Update history
      setHistory({
        ...history,
        undo: newUndo,
        redo: newRedo,
        mapId: mapItem.id,
      })
    }
  }

  const onNodeDrag = useCallback(
    (_event: React.MouseEvent, node: Node) => {
      if (node.type !== 'node' && !node.parentNode) {
        return
      }

      const intersections = getIntersectingNodes(node).filter(
        (n) => n.type === 'group',
      )
      const groupClassName =
        intersections.length && node.parentNode !== intersections[0]?.id
          ? 'active'
          : ''

      useReactFlowStore.setState((state) => {
        const updatedNodes = state.nodes.map((n) => {
          if (n.type === 'group') {
            return {
              ...n,
              className: intersections.some(
                (intersection) => intersection.id === n.id,
              )
                ? groupClassName
                : '',
            }
          } else if (n.id === node.id) {
            return {
              ...n,
              position: node.position,
            }
          }
          return { ...n }
        })

        return { nodes: updatedNodes }
      })
    },
    [getIntersectingNodes],
  )

  useEffect(() => {
    setHasSwitchedMap(true)
    useReactFlowStore.setState({
      history: {
        undo: [],
        redo: [],
      },
    })
    setSearchText('')
    const newNodes = JSON.parse(mapItem.nodes as string) as Node[]
    const newNotesNotSelected = newNodes.map((node) => {
      return { ...node, selected: false }
    })
    setNodes(newNotesNotSelected)
    setMapName(mapItem.name)
  }, [mapItem])

  const customApplyNodeChanges = useCallback(
    (changes: NodeChange[], nodes: Node[]): Node[] => {
      setHelperLineHorizontal(undefined)
      setHelperLineVertical(undefined)

      if (
        changes.length === 1 &&
        changes[0].type === 'position' &&
        changes[0].dragging &&
        changes[0].position
      ) {
        const helperLines = getHelperLines(changes[0], nodes)

        changes[0].position.x =
          helperLines.snapPosition.x ?? changes[0].position.x
        changes[0].position.y =
          helperLines.snapPosition.y ?? changes[0].position.y

        setHelperLineHorizontal(helperLines.horizontal)
        setHelperLineVertical(helperLines.vertical)
      }

      return applyNodeChanges(changes, nodes)
    },
    [],
  )

  const onNodesChange = useCallback(
    (changes: NodeChange[]) => {
      if (!isEditing) {
        // stop user from changing nodes in view mode
        return
      }
      const currentNodes = useReactFlowStore.getState().nodes
      const newNodes = customApplyNodeChanges(changes, currentNodes)
      useReactFlowStore.setState({ nodes: newNodes })
    },
    [isEditing],
  )

  const onConnect = useCallback(
    (edge) => setEdges((eds) => addEdge(edge, eds)),
    [setEdges],
  )

  const onDragOver = useCallback((event: React.DragEvent) => {
    event.preventDefault()
    event.dataTransfer.dropEffect = 'move'
  }, [])

  const handleShapeDoubleClick = (shape: string) => {
    const lastNode = nodes[nodes.length - 1]

    // Default position if there are no nodes yet
    let newPosition = { x: 20, y: 20 }

    // If there's at least one node, calculate the new position based on the last node's position
    if (lastNode) {
      newPosition = {
        x: lastNode.position.x + 20,
        y: lastNode.position.y + 20,
      }
    }

    const extraNode = {
      id: uuid(),
      data: {
        shape: shape,
        label: 'New Node',
        width: 100,
        height: 100,
        fontSize: 12,
      },
      position: newPosition,
      type:
        shape.indexOf('swimlane') > 0
          ? 'ReactMapGroupNode'
          : 'ReactMapCustomNode',
    }

    setNodes([...nodes, extraNode])
  }

  const nodeTypes = useMemo(
    () => ({
      ReactMapCustomNode: ReactMapCustomNode,
      ReactMapGroupNode: ReactMapGroupNode,
    }),
    [],
  )

  const handleSetNodeUrl = (newUrl: string) => {
    setNodeUrl(newUrl)
    const selectedNodeId = nodes.find((node) => node.selected === true)?.id
    if (selectedNodeId) {
      const updatedNodes = nodes.map((node) => {
        if (node.id === selectedNodeId) {
          const newData = { ...node.data }
          newData.url = newUrl
          return { ...node, data: newData }
        }
        return node
      })
      setNodes(updatedNodes)
    }
  }

  const handleSetNodeFillColor = (newColor: string) => {
    setNodeFillColor(newColor)
    const selectedNodeId = nodes.find((node) => node.selected === true)?.id
    if (selectedNodeId) {
      const updatedNodes = nodes.map((node) => {
        if (node.id === selectedNodeId) {
          const newData = { ...node.data }
          newData.shapeColor = newColor
          return { ...node, data: newData }
        }
        return node
      })
      setNodes(updatedNodes)
    }
  }

  const handleSetNodeLabelColor = (newColor: string) => {
    setNodeLabelColour(newColor)
    const selectedNodeId = nodes.find((node) => node.selected === true)?.id
    if (selectedNodeId) {
      const updatedNodes = nodes.map((node) => {
        if (node.id === selectedNodeId) {
          const newData = { ...node.data }
          newData.labelColor = newColor
          return { ...node, data: newData }
        }
        return node
      })
      setNodes(updatedNodes)
    }
  }

  const handleSetNodeFontSize = (newSize: number) => {
    setNodeFontSize(newSize)
    const selectedNodeId = nodes.find((node) => node.selected === true)?.id
    if (selectedNodeId) {
      const updatedNodes = nodes.map((node) => {
        if (node.id === selectedNodeId) {
          const newData = { ...node.data }
          newData.fontSize = newSize
          return { ...node, data: newData }
        }
        return node
      })
      setNodes(updatedNodes)
    }
  }

  const handleSetNodeStrokeColor = (newColor: string) => {
    setNodeStrokeColor(newColor)
    const selectedNodeId = nodes.find((node) => node.selected === true)?.id
    if (selectedNodeId) {
      const updatedNodes = nodes.map((node) => {
        if (node.id === selectedNodeId) {
          const newData = { ...node.data }
          newData.strokeColor = newColor
          return { ...node, data: newData }
        }
        return node
      })
      setNodes(updatedNodes)
    }
  }

  const handleSetEdgeStrokeColor = (newColor: string) => {
    // Find the selected edge by id
    const selectedEdgeId = edges.find((edge) => edge.selected === true)?.id
    setEdgeStrokeColor(newColor)
    if (selectedEdgeId) {
      const updatedEdges = edges.map((edge: Edge) => {
        if (edge.id === selectedEdgeId) {
          // Create a new style object with the updated stroke color
          const updatedStyle = { ...edge.style, stroke: newColor }

          // Update the color properties in markerEnd and markerStart
          if (edge.markerEnd) {
            ;(edge.markerEnd as Record<string, any>).color = newColor
          }
          if (edge.markerStart) {
            ;(edge.markerStart as Record<string, any>).color = newColor
          }

          // Return a new edge object with the updated style property
          return {
            ...edge,
            style: updatedStyle,
          }
        }
        return edge
      })

      setEdges(updatedEdges)
    }
  }

  const handleSetEdgeStrokeThickness = (thickness: string | number) => {
    // Find the selected edge by id
    const selectedEdgeId = edges.find((edge) => edge.selected === true)?.id
    setEdgeStrokeThickness(thickness)
    if (selectedEdgeId) {
      const updatedEdges = edges.map((edge) => {
        if (edge.id === selectedEdgeId) {
          const updatedStyle = { ...edge.style, strokeWidth: thickness }

          return {
            ...edge,
            style: updatedStyle,
          }
        }
        return edge
      })

      // Update the edges state
      setEdges(updatedEdges)
    }
  }

  const handleSetEdgeStrokeType = (selectedValue: string) => {
    setEdgeStrokeType(selectedValue)
    const selectedEdgeId = edges.find((edge) => edge.selected === true)?.id
    if (selectedEdgeId) {
      const updatedEdges = edges.map((edge) => {
        if (edge.id === selectedEdgeId) {
          return { ...edge, type: selectedValue }
        }
        return edge
      })
      setEdges(updatedEdges)
    }
  }

  const updateHistoryWithDebounce = (nodes, edges) => {
    if (hasSwitchedMap) {
      useReactFlowStore.setState({
        history: {
          undo: [],
          redo: [],
        },
      })
      setHasSwitchedMap(false)
      return
    }
    if (isUndoing) {
      setIsUndoing(false)
      return
    }
    if (nodes.length === 0 && edges.length === 0) {
      return
    }
    // get last item in undo stack
    const lastUndo =
      useReactFlowStore.getState().history.undo[
        useReactFlowStore.getState().history.undo.length - 1
      ]

    // if the current state is the same as the last undo, don't update the undo stack
    if (
      JSON.stringify(lastUndo?.nodes) === JSON.stringify(nodes) &&
      JSON.stringify(lastUndo?.edges) === JSON.stringify(edges)
    ) {
      return
    }

    useReactFlowStore.setState((state) => ({
      history: {
        ...state.history,
        undo: [...state.history.undo, { nodes, edges, mapId: mapItem.id }],
        redo: [], // Clear redo stack on new change
      },
    }))
  }

  const handleSetEdgeLabelText = (labelText: string) => {
    setEdgeLabelText(labelText)
    const selectedEdgeId = edges.find((edge) => edge.selected === true)?.id
    if (selectedEdgeId) {
      const updatedEdges = edges.map((edge) => {
        if (edge.id === selectedEdgeId) {
          return { ...edge, label: labelText }
        }
        return edge
      })
      setEdges(updatedEdges)
    }
  }

  const handleSetEdgeEndType = (type: string, end: number) => {
    const enumValueMap = {
      arrow: MarkerType.Arrow,
      arrowclosed: MarkerType.ArrowClosed,
      none: null,
    }

    const enumValue = enumValueMap[type] || null

    const setEdgeType = end === 1 ? setEdgeEndOne : setEdgeEndTwo

    setEdgeType(type)

    const selectedEdgeId = edges.find((edge) => edge.selected === true)?.id

    if (selectedEdgeId) {
      const updatedEdges = edges.map((edge) => {
        if (edge.id === selectedEdgeId) {
          const markerToUpdate = end === 1 ? 'markerStart' : 'markerEnd'
          const strokeColor = edge.style?.stroke || 'black'

          return {
            ...edge,
            [markerToUpdate]: {
              type: enumValue,
              color: strokeColor,
            },
          }
        }
        return edge
      })

      setEdges(updatedEdges)
    }
  }

  useEffect(() => {
    setAllowNodeEditing(nodes.some((node) => node.selected === true))

    setAllowStrokeEditing(edges.some((edge) => edge.selected === true))

    setNodeFillColor(
      nodes.find((node) => node.selected === true)?.data?.shapeColor,
    )
    setNodeLabelText(
      nodes.find((node) => node.selected === true)?.data?.label || '',
    )

    setNodeFontSize(
      nodes.find((node) => node.selected === true)?.data?.fontSize || 12,
    )

    setNodeStrokeColor(
      nodes.find((node) => node.selected === true)?.data?.strokeColor ||
        '#000000',
    )
    setEdgeStrokeType(
      edges.find((edge) => edge.selected === true)?.type || 'smoothstep',
    )
    setEdgeEndOne(
      (
        edges.find((edge) => edge.selected === true)?.markerStart as Record<
          string,
          any
        >
      )?.type || 'none',
    )
    setEdgeEndTwo(
      (
        edges.find((edge) => edge.selected === true)?.markerEnd as Record<
          string,
          any
        >
      )?.type || 'none',
    )
    setEdgeStrokeColor(
      edges.find((edge) => edge.selected === true)?.style?.stroke || '#000000',
    )
    setEdgeStrokeThickness(
      edges.find((edge) => edge.selected === true)?.style?.strokeWidth || 2,
    )
    setEdgeLabelText(
      edges.find((edge) => edge.selected === true)?.label?.toString() || '',
    )
    setNodeLabelColour(
      nodes.find((node) => node.selected === true)?.data?.labelColor ||
        '#000000',
    )
    setNodeUrl(nodes.find((node) => node.selected === true)?.data?.url || '')

    setSelectedNodeShape(
      nodes.find((node) => node.selected === true)?.data?.shape || '',
    )

    setLegend(handleSetLegend())
  }, [edges, nodes])

  useEffect(() => {
    const saveChanges = async () => {
      await saveMap(mapItem.id, false)
    }
    updateHistoryWithDebounce(debouncedNodes, debouncedEdges)
    saveChanges()
  }, [debouncedNodes, debouncedEdges])

  useEffect(() => {
    // Set Intercom position to 300px from the right on this page
    window.Intercom('update', {
      alignment: 'right',
      horizontal_padding: 300,
    })

    // Cleanup to reset Intercom position when leaving the page
    return () => {
      window.Intercom('update', {
        alignment: 'right',
        horizontal_padding: 0,
      })
    }
  }, [])

  return (
    <>
      {/*// Must be sx as ReactFlow cries when it's TW classes*/}
      <Stack direction="row" spacing={2} sx={{ height: '95vh', width: '100%' }}>
        <Box sx={{ height: '100vh' }} className={'w-full'}>
          <div className="dndflow">
            <div className="reactflow-wrapper" ref={reactFlowWrapper}>
              <ReactFlow
                nodes={nodes}
                edges={edges}
                onNodesChange={onNodesChange}
                onEdgesChange={(edges) => {
                  if (!isEditing) return
                  onEdgesChange(edges)
                }}
                multiSelectionKeyCode="Shift"
                onConnect={(params) => isEditing && onConnect(params)}
                minZoom={0}
                maxZoom={5}
                defaultEdgeOptions={defaultEdgeOptions}
                onInit={setReactFlowInstance}
                connectionLineType={ConnectionLineType.SmoothStep}
                nodeTypes={nodeTypes}
                connectionMode={ConnectionMode.Loose}
                proOptions={proOptions}
                fitView={edges.length > 1 ? true : false}
                elevateEdgesOnSelect
                elevateNodesOnSelect
                onDrop={onDropNode}
                onNodeDrag={onNodeDrag}
                onNodeDragStop={onNodeDragStop}
                onDragOver={onDragOver}
                snapToGrid={true}
                snapGrid={[5, 5]}
                edgesUpdatable={isEditing}
                edgesFocusable={isEditing}
                nodesDraggable={isEditing}
                nodesConnectable={isEditing}
                nodesFocusable={isEditing}
              >
                <Background
                  variant={BackgroundVariant.Dots}
                  gap={12}
                  size={1}
                  className={'bg-gray-50'}
                />
                <ReactMapHelperLines
                  horizontal={helperLineHorizontal}
                  vertical={helperLineVertical}
                />

                <ReactMapControlPanelUpperToolbar
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  saveMap={saveMap}
                  mapName={mapName}
                  mapId={mapItem.id}
                  cancelSavingMap={cancelSavingMap}
                  duplicateMap={duplicateMap}
                  fullScreenMap={fullScreenMap}
                  setFullScreenMap={setFullScreenMap}
                  duplicateMapLoading={duplicateMapLoading}
                  deleteReactMapLoading={deleteReactMapLoading}
                  saveMapLoading={saveMapLoading}
                  deleteReactMap={deleteReactMap}
                  linkedWorkflowsRows={linkedWorkflowsRows}
                  legendRef={legendRef}
                  settingsModal={settingsModal}
                  mapInTasks={mapInTasks}
                  isPublished={mapItem.status === 'PUBLISHED'}
                  publishMap={publishMap}
                  publishingMapLoading={publishingMapLoading}
                  deselectNodes={deselectNodes}
                  undoAction={undo}
                  redoAction={redo}
                />
              </ReactFlow>
            </div>
          </div>
        </Box>
        <Box
          sx={{ height: '100%' }}
          className={'w-[300px] overflow-y-scroll border p-3'}
        >
          <ReactMapControlPanel
            mapName={mapName}
            setMapName={setMapName}
            onShapeDoubleClick={handleShapeDoubleClick}
            allowNodeEditing={allowNodeEditing}
            allowStrokeEditing={allowStrokeEditing}
            nodeFillColor={nodeFillColor}
            nodeStrokeColor={nodeStrokeColor}
            setNodeStrokeColor={handleSetNodeStrokeColor}
            setNodeFillColor={handleSetNodeFillColor}
            setEdgeStrokeType={handleSetEdgeStrokeType}
            edgeStrokeType={edgeStrokeType}
            edgeEndOne={edgeEndOne}
            edgeEndTwo={edgeEndTwo}
            setEdgeEnd={handleSetEdgeEndType}
            edgeStrokeColor={edgeStrokeColor}
            setEdgeStrokeColor={handleSetEdgeStrokeColor}
            edgeStrokeThickness={edgeStrokeThickness}
            setEdgeStrokeThickness={handleSetEdgeStrokeThickness}
            edgeLabelText={edgeLabelText}
            setEdgeLabelText={handleSetEdgeLabelText}
            nodeLabelText={nodeLabelText}
            setNodeLabelText={handleSetNodeText}
            nodeLabelColour={nodeLabelColour}
            setNodeLabelColour={handleSetNodeLabelColor}
            nodeUrl={nodeUrl}
            setNodeUrl={handleSetNodeUrl}
            isEditing={isEditing}
            legend={legend}
            setLegend={setLegend}
            legendRef={legendRef}
            saveMap={saveMap}
            mapId={mapItem.id}
            saveMapLoading={saveMapLoading}
            nodeShape={selectedNodeShape}
            duplicateNode={duplicateNode}
            setFontSize={handleSetNodeFontSize}
            fontSize={nodeFontSize}
          />
        </Box>
      </Stack>
      <RecordSettingsModal
        modalVisible={settingsModal}
        record={{
          id: mapItem.id,
          type: 'ReactMap' as TableName,
          name: mapItem.name,
          description: mapItem.description,
          status: mapItem.status,
          parentId: mapItem.reactMapCategoryId,
          moveList: categoryList,
          isTemplate: mapItem.isTemplate,
        }}
        config={isSuperAdmin ? reactMapConfigSuperAdmin : reactMapConfig}
        deleteConfirmOverride={({ afterwards }) =>
          confirm({
            title: `Delete ${mapItem?.name}?`,
            description: (
              <ReactMapDeleteDescription
                mapInTasks={mapInTasks}
                linkedWorkflowsRows={linkedWorkflowsRows}
              />
            ),
            confirmationText: 'Delete',
            confirmationButtonProps: {
              color: 'error',
              className: 'bg-red-500',
            },
          }).then(() => {
            afterwards()
          })
        }
        onDelete={() => {
          deleteMapMutation({
            variables: {
              id: mapItem.id,
            },
            refetchQueries: [
              'FindReactMapsSidePanelQuery',
              'FindReactMapEditorQuery',
              'FindReactMapQuery',
            ],
            awaitRefetchQueries: true,

            onCompleted: () => {
              toast.success(mapItem.name + ' Deleted')
              settingsModal.setFalse()
            },
            onError: (error) => {
              toast.error(error.message)
            },
          })
        }}
        onSave={(record) => {
          updateMapMutationWithRefetch({
            variables: {
              id: mapItem.id,
              input: {
                isTemplate: record.isTemplate,
                status: record.status as LearnerStatus,
                name: record.name,
              },
            },
            refetchQueries: [
              'FindReactMapsSidePanelQuery',
              'FindReactMapEditorQuery',
              'FindReactMapQuery',
            ],
            awaitRefetchQueries: true,

            onCompleted: () => {
              toast.success(mapItem.name + ' Saved')
              settingsModal.setFalse()
            },
            onError: (error) => {
              toast.error(error.message)
            },
          })
        }}
      />
      <LinkedContentBadge
        resourceId={mapItem?.id}
        resourceType={LinkedResourceType.PROCESS_MAP}
        returnLinkedContent={setLinkedWorkflowsRows}
      />
    </>
  )
}
export default ReactMapEditor
