import React, { FC, useState } from 'react'

import { Box, Stack } from '@mui/material'

import Accordion from 'src/components/Library/Accordion/Accordion'
import ReactMapControlPanelLineProperties from 'src/components/ReactMap/ReactMapControlPanelLineProperties'
import ReactMapControlPanelShapeMenu from 'src/components/ReactMap/ReactMapControlPanelShapeMenu'
import ReactMapControlPanelShapeProperties from 'src/components/ReactMap/ReactMapControlPanelShapeProperties'
import ReactMapControlPanelTitleSection from 'src/components/ReactMap/ReactMapControlPanelTitleSection'
import ReactMapLegend from 'src/components/ReactMap/ReactMapLegend'
import {
  endTypes,
  legendShapes,
  lineShapes,
  strokeTypes,
  swimLanes,
  thicknessTypes,
} from 'src/components/ReactMap/utils'

export type legendType = {
  label: string
  color: string
}

export interface ReactMapControlPanelProps {
  mapName: string
  setMapName: (mapName: string) => void
  onShapeDoubleClick: (shape: string) => void
  allowNodeEditing: boolean
  allowStrokeEditing: boolean
  nodeFillColor: string
  setNodeFillColor: (nodeFillColor: string) => void
  nodeStrokeColor: string
  setNodeStrokeColor: (nodeStrokeColor: string) => void
  edgeStrokeType: string
  setEdgeStrokeType: (edgeStrokeType: string) => void
  edgeEndOne: string
  setEdgeEnd: (edgeEndOne: string, end: number) => void
  edgeEndTwo: string
  edgeStrokeColor: string
  setEdgeStrokeColor: (edgeStrokeColor: string) => void
  edgeStrokeThickness: number | string
  setEdgeStrokeThickness: (edgeStrokeThickness: number | string) => void
  edgeLabelText: string
  setEdgeLabelText: (edgeLabelText: string) => void
  nodeLabelText: string
  setNodeLabelText: (nodeLabelText: string) => void
  nodeLabelColour: string
  setNodeLabelColour: (nodeLabelColour: string) => void
  nodeUrl: string
  setNodeUrl: (nodeUrl: string) => void
  legend: legendType[]
  isEditing: boolean
  setLegend: (legend: legendType[]) => void
  legendRef: React.RefObject<HTMLDivElement>
  saveMap: (mapId: number, refetch: boolean) => void
  saveMapLoading: boolean
  nodeShape: string
  duplicateNode: () => void
  mapId: number
  fontSize: number
  setFontSize: (fontSize: number) => void
}

const ReactMapControlPanel: FC<ReactMapControlPanelProps> = ({
  mapName,
  setMapName,
  onShapeDoubleClick,
  allowNodeEditing,
  allowStrokeEditing,
  nodeFillColor,
  setNodeFillColor,
  nodeStrokeColor,
  setNodeStrokeColor,
  edgeStrokeType,
  setEdgeStrokeType,
  edgeEndOne,
  setEdgeEnd,
  edgeEndTwo,
  edgeStrokeColor,
  setEdgeStrokeColor,
  edgeStrokeThickness,
  setEdgeStrokeThickness,
  edgeLabelText,
  setEdgeLabelText,
  nodeLabelText,
  setNodeLabelText,
  nodeLabelColour,
  setNodeLabelColour,
  nodeUrl,
  setNodeUrl,
  legend,
  isEditing,
  setLegend,
  legendRef,
  saveMap,
  saveMapLoading,
  nodeShape,
  duplicateNode,
  mapId,
  fontSize,
  setFontSize,
}) => {
  const onDragStart = (
    event: React.DragEvent<HTMLSpanElement>,
    nodeType: string,
  ) => {
    event.dataTransfer.setData('application/reactflow', nodeType)
    event.dataTransfer.effectAllowed = 'move'
  }
  const [isEditingTitle, setIsEditingTitle] = useState(false)

  return (
    <Stack spacing={2} direction="column">
      <ReactMapControlPanelTitleSection
        mapName={mapName}
        setMapName={setMapName}
        isEditing={isEditing}
        isEditingTitle={isEditingTitle}
        setIsEditingTitle={setIsEditingTitle}
        saveMap={() => saveMap(mapId, true)}
        saveMapLoading={saveMapLoading}
      />
      <Box>
        <Stack
          justifyContent="space-between"
          spacing={2}
          className={'overflow-y-auto'}
        >
          {isEditing && (
            <>
              <ReactMapControlPanelShapeMenu
                title={'Shapes'}
                shapes={legendShapes}
                onShapeDoubleClick={onShapeDoubleClick}
                onDragStart={onDragStart}
              />
              <ReactMapControlPanelShapeMenu
                title={'Lines'}
                shapes={lineShapes}
                onShapeDoubleClick={onShapeDoubleClick}
                onDragStart={onDragStart}
              />
              <ReactMapControlPanelShapeMenu
                title={'Swim Lanes'}
                shapes={swimLanes}
                onShapeDoubleClick={onShapeDoubleClick}
                onDragStart={onDragStart}
              />
            </>
          )}

          {allowNodeEditing && (
            <ReactMapControlPanelShapeProperties
              nodeFillColor={nodeFillColor}
              setNodeFillColor={setNodeFillColor}
              nodeStrokeColor={nodeStrokeColor}
              setNodeStrokeColor={setNodeStrokeColor}
              nodeLabelColour={nodeLabelColour}
              setNodeLabelColour={setNodeLabelColour}
              nodeLabelText={nodeLabelText}
              setNodeLabelText={setNodeLabelText}
              nodeUrl={nodeUrl}
              setNodeUrl={setNodeUrl}
              nodeShape={nodeShape}
              legend={legend}
              duplicateNode={duplicateNode}
              setFontSize={setFontSize}
              fontSize={fontSize}
            />
          )}
          {allowStrokeEditing && (
            <ReactMapControlPanelLineProperties
              edgeStrokeColor={edgeStrokeColor}
              setEdgeStrokeColor={setEdgeStrokeColor}
              edgeLabelText={edgeLabelText}
              setEdgeLabelText={setEdgeLabelText}
              edgeStrokeType={edgeStrokeType}
              setEdgeStrokeType={setEdgeStrokeType}
              edgeEndOne={edgeEndOne}
              setEdgeEnd={setEdgeEnd}
              edgeEndTwo={edgeEndTwo}
              edgeStrokeThickness={edgeStrokeThickness}
              setEdgeStrokeThickness={setEdgeStrokeThickness}
              strokeTypes={strokeTypes}
              thicknessTypes={thicknessTypes}
              endTypes={endTypes}
            />
          )}
          <Accordion title="Legend" expanded={true} customstyle={'bg-white'}>
            <div ref={legendRef}>
              <ReactMapLegend
                legend={legend}
                setLegend={setLegend}
                saveMap={saveMap}
                mapId={mapId}
                isEditing={isEditing}
                saveMapLoading={saveMapLoading}
              />
            </div>
          </Accordion>
        </Stack>
      </Box>
    </Stack>
  )
}

export default ReactMapControlPanel
