import { Dispatch, FC, SetStateAction } from 'react'

import { ShareIcon } from '@heroicons/react/24/outline'
import { ReactFlowProvider } from 'reactflow'
import {
  FindReactMapEditorQuery,
  FindReactMapEditorQueryVariables,
} from 'types/graphql'

import { CellFailureProps, type CellSuccessProps } from '@redwoodjs/web'

import { default as LoadingSpinner } from 'src/components/Library/Loading'
import ReactMapEditor from 'src/components/ReactMap/ReactMapEditor'

export const beforeQuery = (props) => {
  return {
    variables: props,
    fetchPolicy: 'no-cache',
  }
}

export const QUERY = gql`
  query FindReactMapEditorQuery($searchId: Int!) {
    mapItem: reactMap(id: $searchId) {
      id
      createdAt
      updatedAt
      createdBy
      updatedBy
      status
      name
      description
      nodes
      connectors
      legend
      isVisible
      isTemplate
      reactMapCategoryId
      reactMapCategory {
        id
        name
      }
    }
    mapInTasks(mapId: $searchId) {
      id
      name
      learnerActivity {
        id
        learnerCourseId
      }
    }
    memberships: membershipsByClient(activeOnly: true) {
      id
      userId
      user {
        id
        name
      }
    }
  }
`
export const Loading = () => <LoadingSpinner />

export const ReactMapEditorCellEmpty = (
  <div className="flex h-[calc(100vh-64px)]">
    <div className="grow p-1 grid place-items-center border-2 border-gray-300 rounded-xl border-dashed m-16">
      <div className="text-center">
        <ShareIcon className="text-gray-400 w-8 h-8 m-auto" />
        <span className="mt-2 block text-sm font-medium text-gray-900">
          Select a map
        </span>
      </div>
    </div>
  </div>
)

export type FindReactMapEditorQueryProps = CellSuccessProps<
  FindReactMapEditorQuery,
  FindReactMapEditorQueryVariables
> & {
  fullScreenMap: boolean
  setFullScreenMap: Dispatch<SetStateAction<boolean>>
  categoryList: CategoryList
}
export type CategoryList = { text: string; value: number; label: string }[]

export type CellMapInTask = FindReactMapEditorQueryProps['mapInTasks']
export type ReactMapItem = FindReactMapEditorQueryProps['mapItem']

export const Empty = () => {
  return ReactMapEditorCellEmpty
}

export const Failure = ({ error }: CellFailureProps) => (
  <div style={{ color: 'red' }}>Error: {error.message}</div>
)

export const Success: FC<FindReactMapEditorQueryProps> = ({
  fullScreenMap,
  setFullScreenMap,
  mapItem,
  mapInTasks,
  categoryList,
}) => {
  return mapItem ? (
    <ReactFlowProvider>
      <ReactMapEditor
        mapItem={mapItem}
        fullScreenMap={fullScreenMap}
        setFullScreenMap={setFullScreenMap}
        mapInTasks={mapInTasks}
        categoryList={categoryList}
      />
    </ReactFlowProvider>
  ) : (
    ReactMapEditorCellEmpty
  )
}
