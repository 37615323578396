import React, { FC } from 'react'

import { Grid2 as Grid, Typography } from '@mui/material'

import Accordion from 'src/components/Library/Accordion/Accordion'
import ReactMapShape from 'src/components/ReactMap/ReactMapShape'

export interface ReactMapControlPanelShapeMenuProps {
  title: string
  shapes: string[]
  onShapeDoubleClick: (shape: string) => void
  onDragStart: (
    event: React.DragEvent<HTMLSpanElement>,
    nodeType: string,
  ) => void
}

const ReactMapControlPanelShapeMenu: FC<ReactMapControlPanelShapeMenuProps> = ({
  title,
  shapes,
  onShapeDoubleClick,
  onDragStart,
}) => {
  const renderShape = (shape) => {
    switch (shape) {
      case 'text':
        return (
          <Typography
            onDoubleClick={() => onShapeDoubleClick(shape)}
            onDragStart={(event) => onDragStart(event, shape)}
            draggable
            align="center"
            className={
              'cursor-grab bg-transparent hover:bg-gray-100 hover:shadow-xl'
            }
          >
            Text
          </Typography>
        )
      default:
        return (
          <ReactMapShape
            onDoubleClick={() => onShapeDoubleClick(shape)}
            onDragStart={(event) => onDragStart(event, shape)}
            draggable
            shape={shape}
            width={30}
            height={30}
            highlight={true}
          />
        )
    }
  }

  return (
    <Accordion title={title} expanded={true} customstyle={'bg-white'}>
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        {shapes.map((shape) => (
          <Grid
            size={{ xs: 6, sm: 3 }}
            key={shape}
            className="flex items-center justify-center"
          >
            {renderShape(shape)}
          </Grid>
        ))}
      </Grid>
    </Accordion>
  )
}

export default ReactMapControlPanelShapeMenu
