import React, { FC, useState } from 'react'

import {
  styled,
  Stack,
  Popover,
  IconButton,
  Tooltip,
  Typography,
  Box,
} from '@mui/material'

import { legendType } from 'src/components/ReactMap/ReactMapControlPanel'

const ColorBox = styled(Box)({
  width: 50,
  height: 50,
  cursor: 'pointer',
  '&:hover': {
    opacity: 0.75,
  },
  border: '1px solid #000',
})

interface ReactMapColorPickerProps {
  legend: legendType[]
  setNodeFillColor: (nodeFillColor: string) => void
  nodeFillColor: string
}

const ReactMapColorPicker: FC<ReactMapColorPickerProps> = ({
  setNodeFillColor,
  legend,
  nodeFillColor,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'color-popover' : undefined

  return (
    <Box>
      <Tooltip title={'Select from existing shape colours in use'}>
        <IconButton onClick={handleClick} aria-describedby={id} size="small">
          <Box
            className={`h-8 w-8 border border-gray-300 ${
              legend[0]?.color ? `bg-[${legend[0].color}]` : 'bg-transparent'
            }`}
            style={{ backgroundColor: nodeFillColor || 'transparent' }} // Inline style for dynamic bg color
          ></Box>
        </IconButton>
      </Tooltip>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box className={'border-1 p-2'}>
          <Stack alignItems={'center'}>
            <Typography>Select from existing colours</Typography>
            <Box
              display="grid"
              gridTemplateColumns="repeat(4, 1fr)"
              gap={1}
              p={1}
            >
              {legend.map((item, index) => (
                <ColorBox
                  key={index}
                  bgcolor={item.color}
                  onClick={() => {
                    setNodeFillColor(item.color)
                    handleClose()
                  }}
                />
              ))}
            </Box>
          </Stack>
        </Box>
      </Popover>
    </Box>
  )
}

export default ReactMapColorPicker
