import React, { FC } from 'react'

import {
  Box,
  FormControl,
  InputLabel,
  Select,
  Stack,
  TextField,
} from '@mui/material'
import MenuItem from '@mui/material/MenuItem'
import { MuiColorInput } from 'mui-color-input'

import Accordion from 'src/components/Library/Accordion/Accordion'

export type lineOptionsType = {
  label: string
  value: string | number
}

export interface ReactMapControlPanelLinePropertiesProps {
  edgeStrokeColor: string
  setEdgeStrokeColor: (edgeStrokeColor: string) => void
  edgeLabelText: string
  setEdgeLabelText: (edgeLabelText: string) => void
  edgeStrokeType: string
  setEdgeStrokeType: (edgeStrokeType: string) => void
  edgeEndOne: string
  setEdgeEnd: (edgeEndOne: string, end: number) => void
  edgeEndTwo: string
  edgeStrokeThickness: string | number
  setEdgeStrokeThickness: (edgeStrokeThickness: string | number) => void
  strokeTypes: lineOptionsType[]
  thicknessTypes: lineOptionsType[]
  endTypes: lineOptionsType[]
}

const ReactMapControlPanelLineProperties: FC<
  ReactMapControlPanelLinePropertiesProps
> = ({
  edgeStrokeColor,
  setEdgeStrokeColor,
  edgeLabelText,
  setEdgeLabelText,
  edgeStrokeType,
  setEdgeStrokeType,
  edgeEndOne,
  setEdgeEnd,
  edgeEndTwo,
  edgeStrokeThickness,
  setEdgeStrokeThickness,
  strokeTypes,
  thicknessTypes,
  endTypes,
}) => {
  return (
    <Accordion title="Line Properties" expanded={true} customstyle={'bg-white'}>
      <Box>
        <Stack spacing={2}>
          <MuiColorInput
            label="Line Color"
            size={'small'}
            value={edgeStrokeColor}
            onChange={(color) => {
              setEdgeStrokeColor(color)
            }}
            isAlphaHidden={true}
            format="hex"
          />
          <TextField
            label={'Line Text'}
            size={'small'}
            value={edgeLabelText}
            fullWidth
            onChange={(e) => {
              setEdgeLabelText(e.target.value)
            }}
          />

          <FormControl variant="outlined">
            <InputLabel htmlFor="outlined-plan-native-simple">Type</InputLabel>
            <Select
              size={'small'}
              label="Type"
              inputProps={{
                name: 'type',
                id: 'outlined-plan-native-simple',
              }}
              value={edgeStrokeType}
              onChange={(e) => setEdgeStrokeType(e.target.value)}
            >
              {strokeTypes.map((strokeType) => (
                <MenuItem key={strokeType.value} value={strokeType.value}>
                  {strokeType.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl variant="outlined">
            <InputLabel htmlFor="outlined-plan-native-simple">End 1</InputLabel>
            <Select
              size={'small'}
              label="End 1"
              inputProps={{
                name: 'endone',
                id: 'outlined-plan-native-simple',
              }}
              value={edgeEndOne}
              onChange={(e) => setEdgeEnd(e.target.value, 1)}
            >
              {endTypes.map((endType) => (
                <MenuItem key={endType.value} value={endType.value}>
                  {endType.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl variant="outlined">
            <InputLabel htmlFor="outlined-plan-native-simple">End 2</InputLabel>
            <Select
              size={'small'}
              label="End 2"
              inputProps={{
                name: 'endtwo',
                id: 'outlined-plan-native-simple',
              }}
              value={edgeEndTwo}
              onChange={(e) => setEdgeEnd(e.target.value, 2)}
            >
              {endTypes.map((endType) => (
                <MenuItem key={endType.value} value={endType.value}>
                  {endType.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl variant="outlined">
            <InputLabel htmlFor="outlined-plan-native-simple">Width</InputLabel>
            <Select
              size={'small'}
              label="Width"
              inputProps={{
                name: 'width',
                id: 'outlined-plan-native-simple',
              }}
              value={String(edgeStrokeThickness)}
              onChange={(e) => setEdgeStrokeThickness(e.target.value)}
            >
              {thicknessTypes.map((thicknessType) => (
                <MenuItem key={thicknessType.value} value={thicknessType.value}>
                  {thicknessType.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
      </Box>
    </Accordion>
  )
}

export default ReactMapControlPanelLineProperties
