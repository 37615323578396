import React, { useEffect, useMemo, useState } from 'react'

import { FLAG_FEATURES } from 'api/src/common/enums'
import { LearnerStatus } from 'types/graphql'
import { useMediaQuery } from 'usehooks-ts'

import { Metadata } from '@redwoodjs/web'

import PageHeader from 'src/components/PageHeader/PageHeader'
import { CategoryList } from 'src/components/ProcessMapsEditorCell/ProcessMapsEditorCell'
import ReactMapEditorCell from 'src/components/ReactMap/ReactMapEditorCell'
import { ReactMapEditorCellEmpty } from 'src/components/ReactMap/ReactMapEditorCell/ReactMapEditorCell'
import ReactMapSidePanelCell from 'src/components/ReactMap/ReactMapSidePanelCell'
import FeatureToggle from 'src/lib/hooks/LiveFeatureFlag/FeatureToggle'
import useReactFlowStore from 'src/lib/stores/reactMapStores'
import { useAuth } from 'src/Providers'

const ReactMapPage = ({ id }) => {
  const [selectedMap, setSelectedMap] = useState<number | null>(id || null)
  const [fullScreenMap, setFullScreenMap] = useState(false)
  const [categoryList, setCategoryList] = useState<CategoryList>()
  const setIsEditing = useReactFlowStore((state) => state.setIsEditing)
  const isMobile = useMediaQuery('(max-width: 640px)')
  const { currentUser } = useAuth()
  const isAdmin = currentUser?.membershipData?.role !== 'MEMBER'

  const status = useMemo(() => {
    if (isMobile || !isAdmin) return 'PUBLISHED' as LearnerStatus
    // if admin no filtering as we want to see everything
    return undefined as LearnerStatus
  }, [isAdmin, isMobile])

  useEffect(() => {
    setIsEditing(false)
  }, [id])

  useEffect(() => {
    if (!id) return
    if (id === selectedMap) return
    // if we are already on a process map and search for another one with GS
    // the component doesn't unmount so we need to update the selected map
    setSelectedMap(id)
  }, [id])

  return (
    <>
      <Metadata title="Process Maps" description="Process Maps" />
      <div className="h-screen">
        <PageHeader title="Process Maps" parentDataTestId="mapper-page-title" />
        <FeatureToggle feature={FLAG_FEATURES.REACT_MAPS}>
          {isMobile ? (
            <div className="flex flex-col min-h-[calc(100vh-80px)]">
              <ReactMapSidePanelCell
                setSelectedMap={setSelectedMap}
                selectedMap={selectedMap}
                updateCategoryList={setCategoryList}
                status={status}
                isMobile={true}
              />
            </div>
          ) : (
            <div
              className={
                'flex-1 relative z-0 flex w-max-screen h-[calc(100%-64px)]'
              }
            >
              <main
                className={
                  'flex-1 relative z-0 focus:outline-none order-last overflow-hidden w-full '
                }
                id={'react-map-main'}
              >
                {selectedMap ? (
                  <ReactMapEditorCell
                    searchId={selectedMap}
                    setFullScreenMap={setFullScreenMap}
                    fullScreenMap={fullScreenMap}
                    categoryList={categoryList}
                  />
                ) : (
                  ReactMapEditorCellEmpty
                )}
              </main>
              {!fullScreenMap && (
                <div className={'order-first w-[400px] '}>
                  <ReactMapSidePanelCell
                    setSelectedMap={setSelectedMap}
                    selectedMap={selectedMap}
                    updateCategoryList={setCategoryList}
                    status={status}
                    isMobile={false}
                  />
                </div>
              )}
            </div>
          )}
        </FeatureToggle>
      </div>
    </>
  )
}

export default ReactMapPage
