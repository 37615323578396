import React, { FC } from 'react'

import { DocumentDuplicateIcon } from '@heroicons/react/24/outline'
import { Box, Stack, TextField, Tooltip } from '@mui/material'
import { MuiColorInput } from 'mui-color-input'

import Accordion from 'src/components/Library/Accordion/Accordion'
import Button from 'src/components/Library/Button/Button'
import ReactMapColorPicker from 'src/components/ReactMap/ReactMapColorPicker'
import { legendType } from 'src/components/ReactMap/ReactMapControlPanel'
import { lineShapes } from 'src/components/ReactMap/utils'

export interface ReactMapControlPanelShapePropertiesProps {
  legend: legendType[]
  nodeFillColor: string
  setNodeFillColor: (nodeFillColor: string) => void
  nodeStrokeColor: string
  setNodeStrokeColor: (nodeStrokeColor: string) => void
  nodeLabelColour: string
  setNodeLabelColour: (nodeLabelColour: string) => void
  nodeLabelText: string
  setNodeLabelText: (nodeLabelText: string) => void
  nodeUrl: string
  setNodeUrl: (nodeUrl: string) => void
  nodeShape: string
  duplicateNode: () => void
  fontSize: number
  setFontSize: (fontSize: number) => void
}

const ReactMapControlPanelShapeProperties: FC<
  ReactMapControlPanelShapePropertiesProps
> = ({
  nodeFillColor,
  setNodeFillColor,
  nodeStrokeColor,
  setNodeStrokeColor,
  nodeLabelColour,
  setNodeLabelColour,
  nodeLabelText,
  setNodeLabelText,
  nodeUrl,
  setNodeUrl,
  nodeShape,
  legend,
  duplicateNode,
  fontSize,
  setFontSize,
}) => {
  return (
    <Accordion
      title={
        <Stack
          direction={'row'}
          spacing={2}
          alignItems={'center'}
          className={'flex justify-between pr-2'}
        >
          Shape Properties
          <Tooltip title={'Duplicate Node'}>
            <div>
              <Button
                fullWidth={false}
                variant="text"
                className={'min-w-[0] p-2 rounded-full text-gray-500'}
                onClick={(e) => {
                  e.stopPropagation()
                  duplicateNode()
                }}
              >
                <DocumentDuplicateIcon className="text-indigo-500 h-5 w-5" />
              </Button>
            </div>
          </Tooltip>
        </Stack>
      }
      expanded={true}
      customstyle={'bg-white'}
    >
      <Box>
        <Stack spacing={2}>
          {!lineShapes.includes(nodeShape) && nodeShape !== 'text' && (
            <Stack direction={'row'} spacing={0}>
              <MuiColorInput
                size={'small'}
                label="Fill Colour"
                value={nodeFillColor}
                onChange={(color) => {
                  setNodeFillColor(color)
                }}
                isAlphaHidden={true}
                format="hex"
                className={'w-full'}
              />
              {legend.length > 0 && (
                <ReactMapColorPicker
                  legend={legend}
                  setNodeFillColor={setNodeFillColor}
                  nodeFillColor={nodeFillColor}
                />
              )}
            </Stack>
          )}
          {nodeShape !== 'text' && (
            <MuiColorInput
              size={'small'}
              label="Border Colour"
              value={nodeStrokeColor}
              onChange={(color) => {
                setNodeStrokeColor(color)
              }}
              isAlphaHidden={true}
              format="hex"
            />
          )}
          {!lineShapes.includes(nodeShape) && (
            <MuiColorInput
              size={'small'}
              label="Text Colour"
              value={nodeLabelColour}
              onChange={(color) => {
                setNodeLabelColour(color)
              }}
              isAlphaHidden={true}
              format="hex"
            />
          )}
          {!lineShapes.includes(nodeShape) && (
            <TextField
              size={'small'}
              label={'Shape Text'}
              value={nodeLabelText}
              fullWidth
              onChange={(e) => {
                setNodeLabelText(e.target.value)
              }}
            />
          )}
          {!lineShapes.includes(nodeShape) && (
            <TextField
              size="small" // Use small size for the input for consistency
              label="Font Size" // Label for the input
              type="number" // Ensure only numerical values can be inputted
              value={fontSize} // The current font size
              fullWidth // Use the full width of its container
              InputProps={{ inputProps: { min: 1, step: 1 } }} // Set a minimum value of 1 and step to 1 to avoid non-positive numbers
              onChange={(e) => {
                const newSize = Math.min(
                  Math.max(4, Number(e.target.value)),
                  20,
                )
                setFontSize(newSize)
              }}
            />
          )}
          {!lineShapes.includes(nodeShape) && (
            <TextField
              size={'small'}
              label={'Shape URL'}
              value={nodeUrl}
              fullWidth
              onChange={(e) => {
                setNodeUrl(e.target.value)
              }}
            />
          )}
        </Stack>
      </Box>
    </Accordion>
  )
}

export default ReactMapControlPanelShapeProperties
